<template>
  <div class="main-content-box">
    <div class="search-box">
      <div class="search">
        <img class="icon" src="@/assets/works_search_icon.png">
        <input type="text" placeholder="请输入搜索内容" v-model.trim="keywords" @keydown.enter="goWorks">
      </div>
      <div class="main-btn">
        <span class="btn-add" @click="startBak">一键备份</span>
      </div>
    </div>
    <div class="row">
      <div class="box-column box-column-3">
        <img src="@/assets/analysis_icon_web.png">
        <div class="text">
          <div class="count">0</div>
          <div class="title">访问人数</div>
        </div>
      </div>
      <div class="box-column box-column-3">
        <img src="@/assets/analysis_icon_user.png">
        <div class="text">
          <div class="count">{{analysisObj.register_user}}</div>
          <div class="title">注册人数</div>
        </div>
      </div>
      <div class="box-column box-column-3">
        <img src="@/assets/analysis_icon_works.png">
        <div class="text">
          <div class="count">{{analysisObj.submit_user}}</div>
          <div class="title">投稿人数</div>
        </div>
      </div>
    </div>
    <div class="row">
      <template v-for="(item) in analysisObj.works">
        <div class="box-column box-column-2" :key="item.id">
          <div class="race-title">{{item.name}}</div>
          <div class="race-count">共计稿件 {{item.count}}件</div>
          <div class="topic-box">
            <template v-for="(topic) in item.topic">
              <div class="topic-item" :key="topic.id">
                <img :src="topic.img">
                <div class="topic-title">{{topic.name}}</div>
                <div class="topic-count">{{topic.count}}件</div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {getAnalysisMain,startBak} from "@/request/api";
import {Warning} from "@/plugins/warning";

export default {
  name: "home_analysis",
  components:{

  },
  props:{

  },
  data() {
    return {
      keywords:"",
      analysisObj:{}
    }
  },
  computed: {},
  created() {
    this.getAnalysisMain();
  },
  mounted() {
  },
  methods: {
    getAnalysisMain(){
      getAnalysisMain({}).then((res)=>{
        this.analysisObj = res.data;
      })
    },
    startBak(){
      this.$confirm("确定是否开始备份?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const requestData = {};
        startBak(requestData).then(()=>{
          Warning.success('备份已经开始，请等待备份完成后下载备份文件');
          this.getList(0);
        })
      })
    },
    goWorks(){
      this.$router.push({name:"work_list",query:{keywords:this.keywords}}, ()=>{}, ()=>{})
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.search-box{
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
  position: relative;
  .search{
    width: 350px;
    height: 50px;
    border-radius: 30px;
    border: 2px solid #DDD1B4;
    background: #f9f9f9;
    .icon{
      width: 30px;
      height: 30px;
      margin-left: 15px;
      vertical-align:middle;
    }
    & > input{
      height: 50px;
      width: 300px;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align:middle;
    }
  }
  .main-btn{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    position: absolute;
    top: 0;
    right: 0;
    .btn-add{
      display: block;
      width: 200px;
      line-height: 48px;
      font-size: 21px;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
      color: #fff;
      border-radius: 25px;
      background-color: #ba0704;
    }
  }
}
.row{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .box-column{
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  }
  .box-column-2{
    width: calc(50% - 15px);
    margin-bottom: 35px;
    .race-title{
      height: 50px;
      width: 100%;
      margin-top: 27px;
      margin-left: 50px;
      font-size: 36px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #050505;
    }
    .race-count{
      height: 42px;
      margin-left: 50px;
      font-size: 30px;
      font-family: PingFang HK-Medium, PingFang HK;
      font-weight: 500;
      color: #6D7278;
      line-height: 53px;
    }
    .topic-box{
      width: 100%;
      margin-left: 50px;
      margin-right: 50px;
      margin-bottom: 91px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      .topic-item{
        width: 160px;
        margin-top: 35px;
        margin-bottom: 35px;
        float: left;
        & > img{
          width: 78px;
          height: 78px;
          float: left;
        }
        .topic-title{
          width: 55px;
          height: 90px;
          margin-left: 15px;
          font-size: 25px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #000000;
          line-height: 36px;
          float: left;
        }
        .topic-count{
          clear: left;
          font-size: 28px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #6D7278;
        }
      }
    }
  }
  .box-column-3{
    width: calc(33% - 15px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    & > img{
      width: 104px;
      height: 104px;
      margin-top: 55px;
      margin-bottom: 55px;
      margin-left: 55px;
    }
    .text{
      height: 104px;
      margin-top: 55px;
      margin-bottom: 55px;
      margin-left: 20px;
      .count{
        font-size: 33px;
        font-family: Helvetica-Bold-, Helvetica-Bold;
        font-weight: normal;
        color: #282D30;
        line-height: 40px;
        margin-top: 15px;
      }
      .title{
        font-size: 24px;
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
        font-weight: normal;
        color: #74798C;
        line-height: 35px;
      }
    }
  }
}
</style>
